import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Plyo Pushups 8×2 (onto bumper plates)`}</p>
    <p>{`D Ball Scoop Toss (30/20`}{`#`}{`) 8×2 on turf`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`1-Ring Dip`}</p>
    <p>{`1-KBS (53/35)`}</p>
    <p>{`1-Burpee`}</p>
    <p>{`5-Double Unders`}</p>
    <p>{`*`}{`increase reps by 1,1,1,5 each round until time runs out.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      